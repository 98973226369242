import React from 'react';
import { Collapse, Button, } from 'react-bootstrap';
import Rating from 'react-rating';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { datetimeFormatV2, datetimeFormatV3 } from './utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const areEqual = (prevProps, nextProps) => {
	try {
		// debugger
		// Custom comparison logic based on prevProps and nextProps
		// Return true if props are equal, return false otherwise
		// return prevProps.someValue === nextProps.someValue;	
		// return prevProps.is_selected === nextProps.is_selected;
		return prevProps === nextProps;
	} catch (error) {
		return false;
	}
};

const DeviceComponent = React.memo(({ device, favorites, onSelected, onUnSelected }) => {
	// console.log(`DeviceComponent - render... serial: ${props.device.serial} `)
	// const { device, favorites, onSelected, onUnSelected, } = props;

	const [sty, setSty] = useState('device-ok-hilight');

	const [open, setOpen] = useState(false);
	const [isSelected, setIsSelected] = useState(() => {
		// const { favorites, device } = props;
		if (favorites.includes(device.serial)) {
			return true;
		}
		return false;
	});
	// const [savemodeChangeTime, setSavemodeChangeTime] = useState(new Date());
	const [rawUrl, setRawUrl] = useState('');

	useEffect(() => {
		try {
			// const { device, } = props;
			let _rawUrl = 'https://iisnode-api.fleetlocate.asia/api/raw/' + device.serial;
			setRawUrl(_rawUrl)
		} catch (error) {
			console.log(error)
		}
	}, [])

	useEffect(() => {
		try {
			// const eventTime = moment(device.times)
			// const now = moment();
			// const diff = eventTime.diff(now, "minutes")
			var etime = new Date(device.times);
			var ctime = new Date();
			var diff = diffMinutes(ctime, etime);

			// debugger;

			if (diff > 240) {
				setSty("device-issue")
			} else {
				setSty("device-ok-hilight")
				window.setTimeout(() => {
					setSty("device-ok")
				}, 100)
			}
		} catch (error) {
			console.log(error)
		}
	}, [device])

	const renderAvitarV2 = () => {
		try {
			let { is_moving, speed, icon, is_save_mode, event_code } = device;
			if (icon === undefined) {
				// debugger;
			}

			if (is_moving === undefined) is_moving = false;
			if (is_moving === '0' || is_moving === '' || is_moving === undefined) {
				is_moving = false;
			} else {
				is_moving = true;
			}

			// const carImgPath = 'http://gps.fleetlocate.asia/main/';
			let car = '';
			if (event_code === '10017' && speed < 5) {
				car = `./images/vehicles/truck${icon}-pause.png`;
			} else if (!is_save_mode && speed < 5) {
				car = `./images/vehicles/truck${icon}-pause.png`;
			} else if (is_moving && speed < 5) {
				car = `./images/vehicles/truck${icon}-pause.png`;
			} else if (is_moving && speed >= 5) {
				// Moving
				car = `./images/vehicles/truck${icon}-play.png`;
			} else {
				// Stop
				car = `./images/vehicles/truck${icon}-stop.png`;
			}

			return car; //  (<img width="30" src={`${car}`} alt="" />);
		} catch (error) {
			console.log(error);
			return '';
		}
	}

	const diffMinutes = (dt1, dt2) => {
		var diff = (dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		return Math.abs(Math.round(diff));
	}

	const header = () => {
		let { serial, event_name, times, } = device;
		if (event_name === 'EnterPowerSaveMode') {
			event_name = 'EnterSaveMode';
		}

		try {
			if (event_name === undefined) {
				event_name = 'xxxxxxxxxx';
			} else {
				event_name = event_name.replace('FG_', '');

				if (event_name.indexOf('PowerDisconnected') !== -1) {
					event_name = event_name.replace('PowerDisconnected', 'PowerDiscon.');
				}
				if (event_name.indexOf('AutomaticLocate') !== -1) {
					event_name = event_name.replace('AutomaticLocate', 'AutoLocate');
				}
				if (event_name.indexOf('PowerReconnected') !== -1) {
					event_name = event_name.replace('PowerReconnected', 'PowerRecon.');
				}
				if (event_name.indexOf('MovementReport') !== -1) {
					event_name = event_name.replace('MovementReport', 'Movement');
				}
			}
			// console.log(event_name);			
		} catch (error) {
			console.log(error);
			return (<div>ERROR!</div>);
		}

		var bcolor = 'blue';
		// var boxShadow = '0 0 15px 0.5px #68ff16';
		var boxShadow = '0 0 15px 0.5px #ffffff';

		var etime = new Date(times);
		var ctime = new Date();
		var diffMs = diffMinutes(ctime, etime);

		var backgroundImage = 'linear-gradient(90deg, rgb(34, 106, 87) 0%, rgb(42, 191, 52) 100%)'
		etime = datetimeFormatV2(etime)

		if (diffMs > 240) {
			bcolor = 'rgb(76, 64, 70)';
			boxShadow = '';
			backgroundImage = ''
		} else {
			bcolor = 'rgb(2, 100, 58)';
			boxShadow = '0 0 15px 0.5px rgb(42, 191, 52)';

			// window.setTimeout(() => {
			// 	setSty("device-ok")
			// }, 1000)
		}

		return (
			<div className={sty}

			// className={diffMs > 240 ? "device-issue" : sty}				
			// style={{
			// 	backgroundColor: bcolor,
			// 	boxShadow: boxShadow,
			// 	backgroundImage: backgroundImage,
			// 	color: 'white',
			// 	padding: '3px',
			// }}
			>
				<div className={backgroundImage === "" ? "" : "whitebox"}  >
					<span >
						<img width="30" src={`${renderAvitarV2()}`} alt="" />
					</span>
					<span className='header-serial' >
						<span >{serial} </span>
					</span>
				</div>
				<div className='device-collapse-row'>
					<span >[{event_name}] </span>
					<span >{etime}</span>
				</div>
			</div>
		)
	}

	return (
		<div className='device-each-inside'>

			<div className='device-each-inside-header'  >

				<Rating
					// {...props}
					// s tyle={{ zIndex: 1000, position: 'absolute', left: '40px', }}
					className='rating'
					title="FAVOURITES" key={device.serial} stop={1}
					onClick={(v) => {
						if (isSelected === false) {
							setIsSelected(true);
							onSelected(device.serial);
						} else {
							setIsSelected(false);
							onUnSelected(device.serial);
						}
						return false;
					}}
					initialRating={isSelected}
					fullSymbol={<FaStar color={'#e67e22'} size="1rem" />}
					emptySymbol={<FaRegStar size="1rem" />}
				/>

				<Button variant="light"
					onClick={() => setOpen(!open)}
					aria-controls={'collapse-' + device.sim} aria-expanded={open}
				>
					{header()}
				</Button>

			</div>

			{/* 
			<Collapse className='dm-c ollapse' in={open}>
				<div className='dm-c ollapse-inside' id={'collapse' + props.device.sim}	>
			*/}

			<Collapse in={open}>
				<div id={'collapse' + device.sim}	>

					<div className='device-collapse-row'>
						<span>Serial: {device.serial} </span>
						<span>Sim: {device.sim}</span>
						{/* <span>Sim: {props.device.sim} </span><span>VIN: {props.device.vin}</span> */}
					</div>
					<div className='device-collapse-row'>
						<span>
							Savemode change time <br /> {datetimeFormatV3(device.savemode_change_time)}
						</span>
						<span>
							{device.serial.startsWith('8') &&
								<a href={rawUrl} target="_blank" rel="noreferrer">
									<span >
										RAW
									</span>
								</a>
							}
						</span>
					</div>
				</div>
			</Collapse>

		</div>

	);

}, areEqual);

export default DeviceComponent;