import _ from 'lodash'

// eslint-disable-next-line no-extend-native
Number.prototype.padLeft = function (base, chr) {
  var len = (String(base || 10).length - String(this).length) + 1;
  return len > 0 ? new Array(len).join(chr || '0') + this : this;
}

/// dt is string
export const datetimeFormatV3 = (dt) => {
  try {
    var d = new Date(dt);

    // var d = new Date,
    let dformat = [
      d.getDate().padLeft(),
      (d.getMonth() + 1).padLeft(),
      d.getFullYear()].join('/')

      + ' ' +

      [d.getHours().padLeft(),
      d.getMinutes().padLeft(),
      d.getSeconds().padLeft()].join(':');

    return dformat;
    //=> dformat => '05/17/2012 10:52:21'

  } catch (error) {
    return '';
  }
}

/// d is date
export const datetimeFormatV2 = (d) => {
  try {
    // var d = new Date,
    let dformat = [
      d.getDate().padLeft(),
      (d.getMonth() + 1).padLeft(),
      d.getFullYear()].join('/')

      + ' ' +

      [d.getHours().padLeft(),
      d.getMinutes().padLeft(),
      d.getSeconds().padLeft()].join(':');

    return dformat;
    //=> dformat => '05/17/2012 10:52:21'

  } catch (error) {
    return '';
  }
}

export const dateFormat = (date) => {
  const dateStr = String(date || '');

  if (!dateStr) {
    return dateStr;
  }

  return String(dateStr.slice(0, 10) || '')
    .replace(/^(\d\d)(\d)$/g, '$1/$2')
    .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2');
};

export const timeFormat = (time) => {
  const timeStr = String(time || '');

  if (!timeStr) {
    return timeStr;
  }

  return String(timeStr.slice(0, 10) || '')
    .replace(/^(\d\d)(\d)$/g, '$1:$2')
    .replace(/^(\d\d:\d\d)(\d+)$/g, '$1:$2');
};

// TODO: return 
export const dateTimeFormat = (date) => {
  const dateStr = String(date || '');

  if (!dateStr.trim()) {
    return dateStr.trim();
  }

  const formatedDate = String(dateStr.slice(0, 10) || '')
    .replace(/^(\d\d)(\d)$/g, '$1/$2')
    .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2');

  const formatedTime = String(dateStr.slice(11, 19) || '')
    .replace(/^(\d\d)(\d)$/g, '$1:$2')
    .replace(/^(\d\d:\d\d)(\d+)$/g, '$1:$2');

  if (dateStr.charAt(dateStr.length) === ' ') {
    return dateStr;
  }

  if (dateStr.length <= 10) {
    return formatedDate;
  }

  if (dateStr.length <= 19) {
    return `${formatedDate} ${formatedTime}`;
  }

  return `${formatedDate} ${formatedTime} ${dateStr.slice(20, 22)}`;
};

export const getAvitar = (device) => {
  try {
    let { is_moving, speed, icon, is_save_mode, event_code } = device;
    if (icon === undefined) {
      // debugger;
    }
    if (is_moving === undefined) is_moving = false;
    if (is_moving === '0' || is_moving === '' || is_moving === undefined) {
      is_moving = false;
    } else {
      is_moving = true;
    }
    // ./images/vehicles/truck1-stop.png // const carImgPath = 'http://gps.fleetlocate.asia/main/';
    let car = '';
    if (event_code === '10017' && speed < 5) {
      car = `./images/vehicles/truck${icon}-pause.png`;
    } else if (!is_save_mode && speed < 5) {
      car = `./images/vehicles/truck${icon}-pause.png`;
    } else if (is_moving && speed < 5) {
      car = `./images/vehicles/truck${icon}-pause.png`;
    } else if (is_moving && speed >= 5) {
      // Moving
      car = `./images/vehicles/truck${icon}-play.png`;
    } else {
      // Stop
      car = `./images/vehicles/truck${icon}-stop.png`;
    }

    return car;
  } catch (error) {
    console.log(error);
  }
};


const validDateCondition = (date) => !(
  Array.from(date).filter((w) => w !== '/').find(_.isNaN)
  || Array.from(date).filter((w) => w === '/').length > 2
  || String(date).split('/').pop().length > 4
  || String(date).split('/').pop() === '0000'
);

const validTimeCondition = (date) => !(
  Array.from(date.split(' ')[0]).filter((w) => w !== ':').find(_.isNaN)
  || Array.from(date.split(' ')[0]).filter((w) => w === ':').length > 2
  || _.isUndefined(date.split(' ')[1])
);

const validDateTimeCondition = (date) => !(
  !validDateCondition(date.slice(0, 10))
  || !validTimeCondition(date.slice(11))
);

const validDateConditions = {
  date: validDateCondition,
  time: validTimeCondition,
  dateTime: validDateTimeCondition,
};

const UTILS = {
  dateFormat,
  timeFormat,
  dateTimeFormat,
  validDateCondition,
  validTimeCondition,
  validDateTimeCondition,
  validDateConditions,

  // getAvitar,
};


export default UTILS;