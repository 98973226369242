import React, { useEffect, useState } from 'react';
import { FaBell, FaServer, FaCar, FaQrcode, FaStar, FaRegStar, FaHistory, FaSearch, FaCut } from 'react-icons/fa';
import './LogCom.css';
// import './logCom.scss'
// import { datetimeFormatV3 } from  './utils';
// import { SocketContext } from '../socket';
import moment from 'moment';
// import { useDispatch, useSelector } from 'react-redux';

const LogCom = ({ data }) => {

    // console.log(`LogCom - render - data: ${data.length} `)

    // const [logs, setLogs] = useState([]);
    // const { deviceLog, } = useSelector(state => state.device)
    // const dispatch = useDispatch()

    // useEffect(() => {
    //     console.log(`----------------- LogCom.js - counter: ${counter}`)
    // }, [counter])

    // useEffect(() => {
    //     console.log(`LogCom.js - deviceLog: ${JSON.stringify(deviceLog)}`)
    // }, [deviceState])

    // useEffect(() => {
    //     console.log(`LogCom.js - deviceLog: ${JSON.stringify(deviceLog)}`)
    // }, [deviceLog])

    // useEffect(() => {
    //     setLogs((lg) => {
    //         const tmp = [lastEvent].concat(lg)
    //         return tmp.slice(0, 99)
    //     })

    // }, [lastEvent])

    const getAvitar = (device) => {
        try {
            let { is_moving, speed, icon, is_save_mode, event_code } = device;
            if (icon === undefined) {
                // debugger;
            }
            if (is_moving === undefined) is_moving = false;
            if (is_moving === '0' || is_moving === '' || is_moving === undefined) {
                is_moving = false;
            } else {
                is_moving = true;
            }
            // ./images/vehicles/truck1-stop.png // const carImgPath = 'http://gps.fleetlocate.asia/main/';
            let car = '';
            if (event_code === '10017' && speed < 5) {
                car = `./images/vehicles/truck${icon}-pause.png`;
            } else if (!is_save_mode && speed < 5) {
                car = `./images/vehicles/truck${icon}-pause.png`;
            } else if (is_moving && speed < 5) {
                car = `./images/vehicles/truck${icon}-pause.png`;
            } else if (is_moving && speed >= 5) {
                // Moving
                car = `./images/vehicles/truck${icon}-play.png`;
            } else {
                // Stop
                car = `./images/vehicles/truck${icon}-stop.png`;
            }

            return car;
        } catch (error) {
            console.log(error);
        }
    };

    // if (data && data.length > 0) {
    //     return data.map((l, idx) => {
    //         try {              
    //             if (l.event_name) {
    //                 let fcolor = 'inherit'; 
    //                 let bcolor = 'transparent';
    //                 let boxShadow = '';                    

    //                 if (l.event_name.toString().toLowerCase().indexOf('ignitionon') !== -1) {
    //                     fcolor = 'white';                         
    //                     bcolor = 'rgb(2, 100, 58)';                        
    //                     boxShadow = 'rgb(104, 255, 22) 0px 0px 15px 0.5px';
    //                 }
    //                 if (l.event_name.toString().toLowerCase().indexOf('ignitionoff') !== -1) {
    //                     fcolor = 'white'; 
    //                     bcolor = 'rgb(251, 65, 55)';
    //                     boxShadow = 'rgb(255 31 31) 0px 0px 15px 0.5px';
    //                 }

    //                 const avatar = getAvitar(l);

    //                 return (<li key={idx} style={{ marginLeft: '-23px', }}>
    //                     <img src={avatar} alt="Avatar" className="avatar" />
    //                     <span className={"small"} style={{
    //                         boxShadow: boxShadow,
    //                         color: fcolor, backgroundColor: bcolor, padding: '1px 6px',
    //                         marginLeft: '5px', borderRadius: '3px',
    //                     }}>
    //                         {datetimeFormatV3(l.times)}                            
    //                         - [SERIAL: {l.serial}]
    //                         - [VIN: {l.vin}]
    //                         - [EVENT: {l.event_name}]
    //                     </span>
    //                 </li>);
    //             }

    //             return null;
    //         } catch (err) {
    //             console.log(err);
    //             return null;
    //         }
    //     });
    // } else {
    //     return (<span style={{ marginLeft: '-15px', }}>Data coming ...</span>);
    // }

    return (
        <div className="log-style-container">
            <div className="log-style">
                <div className="log-header">
                    <span >
                        <FaHistory color="silver" />
                    </span>
                    <span >
                        Recently event update
                    </span>
                </div>

                <ul className="log" style={{}}>

                    {/* <LogCom data={deviceLog} width={width} /> */}
                    {/* {data && data.length > 0 && data.map(() => {
                        return (
                            <div>xx</div>
                        )
                    })} */}

                    {data && data.length > 0 && data.map((l, idx) => {
                        // console.log(`idx: ${idx} `)
                        //console.log(`device: ${JSON.stringify(l)} `)
                        try {
                            if (l.event_name) {
                                let fcolor = 'inherit';
                                let bcolor = 'transparent';
                                let boxShadow = '';

                                if (l.event_name.toString().toLowerCase().indexOf('ignitionon') !== -1) {
                                    fcolor = 'white';
                                    // bcolor = 'rgb(2, 100, 58)';
                                    bcolor = 'rgb(42, 191, 52)';
                                    boxShadow = 'rgb(104, 255, 22) 0px 0px 15px 0.5px';
                                }
                                if (l.event_name.toString().toLowerCase().indexOf('ignitionoff') !== -1) {
                                    fcolor = 'white';
                                    bcolor = '#f20519'; // 'rgb(251, 65, 55)';
                                    boxShadow = 'rgb(255 31 31) 0px 0px 15px 0.5px';
                                }

                                const avatar = getAvitar(l);

                                return (<li key={idx.toString()} style={{ marginLeft: '-23px', }}>
                                    <img src={avatar} alt="Avatar" className="avatar" />
                                    <span className={"small"} style={{
                                        fontSize: "small",
                                        boxShadow: boxShadow,
                                        color: fcolor, backgroundColor: bcolor, padding: '1px 6px',
                                        marginLeft: '5px', borderRadius: '3px',
                                    }}>
                                        {moment(l.last_date).format("DD/MM HH:mm:ss")}
                                        - [serial: {l.serial}]
                                        - [vin: {l.vin}]
                                        - [event: {l.event_name}]
                                    </span>
                                </li>);
                            }

                            return null;
                        } catch (err) {
                            console.log(err);
                            return null;
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}

export default LogCom;

