import React, { memo } from 'react'
import _ from "lodash"
import { Chart } from "react-google-charts";

// export const data = [
//   ["Task", "Hours per Day"],
//   // ["Work", 11],
//   ["Eat", 5],
//   ["Commute", 20],
//   // ["Watch TV", 2],
//   // ["Sleep", 7],
// ];

// export const options = {
//   title: "",
//   is3D: true,
//   legend: "none",
//   pieSliceText: "label",
//   slices: {
//     0: { color: "#f2ae2e" },
//     1: { color: "#2abf34" },
//     // 2: { color: "#f20519" },
//   },
//   backgroundColor: "#624f56",
// };

{/* <GoogleChart issues={deviceActives} total={devicesTotal} /> */ }

const GoogleChart = memo(({ issues, total }) => {

  // console.log(`GoogleChart - render - issues: ${issues} - total: ${total} `)

  const data = [
    ["Task", "Device"],
    ["Active devices", total - issues],
    ["Inactive devices", issues],
  ];

  const options = {
    title: "",
    is3D: true,
    legend: "none",
    pieSliceText: "label",
    slices: {
      // 0: { color: "#f2ae2e" },
      // 1: { color: "#2abf34" },
      // 2: { color: "#f20519" },

      0: { color: "#2abf34" },
      1: { color: "#4c4046" },
    },
    backgroundColor: "#624f56",
  };

  return (
    <div style={{ backgroundColor: "red", }} >

      <Chart
        chartType="PieChart"
        data={data}
        // options={options}
        options={options}
        width={"100%"}
        height={"400px"}
      />

    </div>

  )
}, (prev, next) => {
  return _.isEqual(prev, next)
})

export default GoogleChart