import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// redux version
// import App from './pages/Main.js'

// vanila 
import App from './AppMain.js'

// import { Provider } from 'react-redux';
// import { store } from './redux/store.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  //   <SocketProvider>
  //     <App />
  //   </SocketProvider>
  // </React.StrictMode>

  // <SocketProvider>
  //   <App />
  // </SocketProvider>

  // <Provider store={store}>
  <App />
  // </Provider>


);
