// change to function component NOW	!
// Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, 
// but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.

import React, { useCallback, useRef } from "react";
import socketIOClient from "socket.io-client";
import _ from 'lodash'
import { FaBell, FaServer, FaCar, FaQrcode, FaStar, FaRegStar, FaHistory, FaCut } from 'react-icons/fa';
// import { GiTruck } from "react-icons/gi";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
// import { TbMessageSearch } from "react-icons/tb";
import Rating from 'react-rating';
// import { CSVLink } from 'react-csv';
// import { InputGroup, Form, } from 'react-bootstrap';

import DeviceComponent from './global/DeviceComponent';
// import PieCom from './components/PieCom';
import GoogleChart from "./components/GoogleChart";
import LogCom from './components/LogCom';

// import DrawerToggleButton from './Toolbar/DrawerToggleButton';
// import SideDrawer from './Toolbar/SideDrawer';
// import B ackdrop from './Toolbar/B ackdrop';
// import FixStepCom from "./FixStepCom";

import TimerCom from "./components/TimerCom"

import { useState, useEffect } from "react";

// just place index.css after the bootstrap.min.css, this fixed my problem
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css'; // speed up when use minify

import './AppMain.scss'
import './App.css';
// import { getAvitar } from "./global/utils";
// import moment from "moment";

// const apiDltDeviceInactive = 'http://io.fleetlocate.asia:85/api/dlt_device_inactive';
// const apiDltDeviceInactiveInternal = 'http://io.fleetlocate.asia:85/api/dlt_device_inactive_internal';

let socket = null;
let deviceLog = []

function useDebounce(cb, delay) {
  const [debounceValue, setDebounceValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb); // re-render function useDebounce when any state changed
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);

  return debounceValue;
}

function AppMain(props) {

  // console.log('AppMain - render...')

  const [state] = useState({});

  const [is_transport_closed, setIs_transport_closed] = useState(false);
  const [ws_connection, setWs_connection] = useState('waiting');
  const [is_timeout, setIs_timeout] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [zoom, setZoom] = useState(1.0);

  // dev	
  const [endpoint] = useState("https://monitor-server.fleetlocate.asia/")
  // useState('http://127.0.0.1:3001/');
  // prod
  // const [endpoint] = useState("http://monitor.fleetlocate.asia:86/");

  const [deviceActives, setDeviceActives] = useState([]);
  // const [online, setOnline] = useState(0);
  const [isSort, setIsSort] = useState(true);
  const [devicesTotal, setDevicesTotal] = useState(0);
  const [devicesWithIssues, setDevicesWithIssues] = useState(0);
  const [isDlt, setIsDlt] = useState(true);
  const [isNotDlt, setIsNotDlt] = useState(true);

  // const [is_chrome, setIs_chrome] = useState(true);
  // const [is_report_generated, setIs_report_generated] = useState(false);

  // const [inactive_data, setInactive_data] = useState([]);
  // const [inactive_header, setInactive_header] = useState(headerInactive);
  // const [generating_text, setGenerating_text] = useState('Generate DLT inactive devices');
  // const [is_report_internal_generated, setIs_report_internal_generated] = useState(false);
  // const [inactive_data_internal, setInactive_data_internal] = useState([]);

  // const [inactive_header_internal, setInactive_header_internal] = useState(headerInactiveInternal);
  // const [generating_text_internal, setGenerating_text_internal] = useState('Generate DLT inactive devices for internal');
  // const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  // const [is_full_screen, setIs_full_screen] = useState(false);
  // const [generate_days, setGenerate_days] = useState(3);

  const [isShowSelects, setIsShowSelects] = useState(false);
  const [mySelects, setMySelects] = useState([]);

  // const [pieData, setPieData] = useState([]);
  // const [nodata, setNodata] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(() => {

    // function updatePie() {
    //   let snomal = parseInt(devicesTotal - devicesWithIssues);
    //   let sfailed = parseInt(devicesWithIssues);
    //   if (snomal === 0 && sfailed === 0) {
    //     snomal = 100;
    //     sfailed = 0;
    //     setNodata(true);
    //   }

    //   let _pieData = [
    //     { x: 'Active', y: snomal },
    //     { x: 'Inactive', y: sfailed }
    //   ];
    //   setPieData(_pieData)
    // }

    var totals = totalCount();
    var issues = totalWithIssueCount();

    setDevicesTotal(totals)
    setDevicesWithIssues(issues)

    // updatePie()
  }, [loaded, isDlt, isNotDlt, deviceActives,])

  useEffect(() => {
    // console.log('on useeffect 3');
    try {
      document.title = 'Biowatch device monitoring system';
      socketConnect();
      return () => {
        if (socket) {
          try {
            socket.destroy();
            // delete socket;
            socket.off()
            socket = null;
          } catch (error) {

          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [])

  const socketCleanup = () => {
    try {
      if (socket) {
        socket.destroy();
        // delete socket;
        socket = null;
      }
    } catch (error) {
      console.log(error)
    }
  }

  const socketConnect = () => {
    try {
      socketCleanup();
      socket = socketIOClient(endpoint, { transports: ['websocket'], forceNew: true, timeout: 3000 });
      socket.emit('request_monitor', 'yes'); // send ack for response all devices

      setLoaded(true);

      socket.on('error', (error) => {
        console.log(error)
        setIs_transport_closed(true);
      });

      socket.on('connect_timeout', (timeout) => {
        console.log(timeout)
        console.log('connect timeout');
      });

      socket.on('connect_error', (error) => {
        console.log(error)
        setIs_timeout(true);
      });

      socket.on('connecting', () => {
        setWs_connection('connecting');
      });

      socket.on('disconnect', (res, par) => {
        setIs_transport_closed(true);

        socket.on('connect', () => {
          socketConnect();
        });
      });

      socket.on('devices', (results) => {
        try {
          setLoaded(false)
          setWs_connection('connected')

          const { devices } = results;

          devices.forEach((d) => {
            d.times = toTimes(d.last_date);
            d.is_device = true;
            d.is_selected = 0;
            if (d.serial !== undefined && d.serial.trim() !== '' && d.serial) {

              // 	// TODO: how to dynamic
              // 	// https://www.appsloveworld.com/reactjs/200/181/set-state-using-dynamic-key-in-react-hook

              state[d.serial] = Object.assign({}, d);
            }
          });

          setLoaded(true);
        } catch (err) {
          console.log(err);
        }
      });

      socket.on('monitor_device', (result) => {
        try {
          let { device } = result;
          device.times = toTimes(device.last_date);
          device.is_device = true;
          device.is_selected = 0;

          // IMPORTANT THIS LINE - ADD/UPDATE DYNAMIC STATE OBJECT .
          if (device) {
            let devices = [device].concat(deviceActives);
            const _devices = devices; // .slice(0, 100)						
            setDeviceActives(_devices)

            // console.log(`device: ${JSON.stringify(device)}`)
            if (deviceLog.length > 100) {
              deviceLog = deviceLog.slice(0, 5);
              deviceLog = [device].concat(deviceLog)
            } else {
              // deviceLog = deviceLog.slice(0, 99);
              deviceLog = [device].concat(deviceLog)
            }

            state[device.serial] = Object.assign({}, device);

            // not work ? /// how to add prev active device and bind to logs box

            // every time set state this component will be rendered, so reduce set state if possible
            // setOnline(online)
            // setIs_transport_closed(false)
          }
        } catch (error) {
          console.log(error);
        }
      });

      // TODO: Cancel device so delete auto
      socket.on('canceled_device', (result) => {
        console.log(result)
        // const { serial } = result;
        // // just remove from state [prop]
        // try {
        // 	// thi s.se tState((prevState) => {					
        // 	// 	delete prevState[serial];
        // 	// 	let newState = removeByKey(prevState, serial);
        // 	// 	return newState;
        // 	// });				
        // }
        // catch (error) {
        // 	console.log(error);
        // }
      });


    } catch (error) {
      console.log(error)
    }
  }

  // const removeByKey = (myObj, deleteKey) => {
  // 	return Object.keys(myObj)
  // 		.filter(key => key !== deleteKey)
  // 		.reduce((result, current) => {
  // 			result[current] = myObj[current];
  // 			return result;
  // 		}, {});
  // };

  // const resize = () => {
  //   setWidth(window.innerWidth);
  // }

  const toTimes = (last_date) => {
    try {
      if (last_date && !last_date.toLowerCase().endsWith('z')) {
        last_date = last_date + 'Z';
      }
      return (new Date(last_date)).getTime(); // / 1000;
    } catch (error) {
      console.log('Error with last_date convertion');
      console.log(error);
    }
    return 0;
  }

  const totalCount = () => {

    let sum = 0;

    var keys = Object.keys(state);

    keys.forEach((s) => {
      let d = {};
      try {
        if (search !== '' && s.indexOf(search) !== -1) {
          if (isStateOfDevice(s)) {
            d = state[s];
            if (d !== undefined) {
              // if (is_dlt && d.dlt_api_type === '2') sum++;
              if (isDlt && parseInt(d.dlt_api_type) > 0) sum++;
              // if (is_not_dlt && d.dlt_api_type !== '2') sum++;
              if (isNotDlt && parseInt(d.dlt_api_type) === 0) sum++;
            }
          }
        }
        if (search === '') {
          if (isStateOfDevice(s)) {
            d = state[s];
            if (d !== undefined) {
              // if (is_dlt && d.dlt_api_type === '2') sum++;
              // if (is_not_dlt && d.dlt_api_type !== '2') sum++;
              if (isDlt === true && parseInt(d.dlt_api_type) > 0) sum++;
              if (isNotDlt === true && parseInt(d.dlt_api_type || '0') === 0) sum++;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }

    });
    return sum;
  }

  const isMorethan4Hours = (milliseconds) => {
    try {
      var etime = new Date(milliseconds);
      var ctime = new Date();
      var diffMs = diffMinutes(ctime, etime);
      if (diffMs > (240)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  const totalWithIssueCount = () => {
    let sum = 0;
    var keys = Object.keys(state);

    keys.map((s) => {
      if (search !== '' && s.indexOf(search) !== -1) {
        if (isStateOfDevice(s)) {
          const d = state[s]; // important
          if (d !== undefined && isMorethan4Hours(d.times)) {
            // if (is_dlt && d.dlt_api_type === '2') sum++;
            // if (is_not_dlt && d.dlt_api_type !== '2') sum++;
            if (isDlt && parseInt(d.dlt_api_type) > 0) sum++;
            if (isNotDlt && parseInt(d.dlt_api_type) === 0) sum++;
          }
        }
      }
      if (search === '') {
        if (isStateOfDevice(s)) {
          const d = state[s];
          if (d !== undefined && isMorethan4Hours(d.times)) {

            // biowatch monitor
            // if (is_dlt && d.dlt_api_type === '2') sum++;
            // if (is_not_dlt && d.dlt_api_type !== '0') sum++;
            if (isDlt && parseInt(d.dlt_api_type) > 0) sum++;
            if (isNotDlt && parseInt(d.dlt_api_type) === '0') sum++;

          }
        }
      }
      return null;
    });
    return sum;
  }

  const isStateOfDevice = (o) => {
    try {
      const device = state[o];

      return Object.keys(device).includes('dlt_api_type');

    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const deviceComponentOnSelected = useCallback((d) => {
    if (!_.includes(mySelects, d)) {
      let _mySelects = [d].concat(mySelects);
      setMySelects(_mySelects);
    }
  }, [mySelects]);

  const deviceComponentOnUnSelected = useCallback((s) => {
    let _mySelects = mySelects.filter((d) => {
      if (d === s) {
        return false;
      }
      return true;
    });
    setMySelects(_mySelects);
  }, [mySelects]);

  // ==================================================
  // T ODO: GT06E - dlt_api_type = 6
  // T ODO: BWX5  - dlt_apy_type = 7

  const RenderDevices = () => {
    try {

      var keys = Object.keys(state);

      var filterKeys = keys.filter((s) => {
        if (search !== '' && s.indexOf(search) !== -1) {
          if (isStateOfDevice(s)) {
            const d = state[s];
            if (d !== undefined) {
              // if (is_dlt && d.dlt_api_type === '2') return true; // [s]; // important
              if (isDlt && parseInt(d.dlt_api_type) > 0) return true;
              // if (is_not_dlt && d.dlt_api_type !== '2') return true; // [s];
              if (isNotDlt && parseInt(d.dlt_api_type) === 0) return true;
            }
            return false;
          }
        }
        if (search === '') {
          if (isStateOfDevice(s)) {
            const d = state[s];
            if (d !== undefined) {
              // if (is_dlt && d.dlt_api_type === '2') return true; // [s]; // important
              if (isDlt && parseInt(d.dlt_api_type) > 0) return true;
              // if (is_not_dlt && d.dlt_api_type !== '2') return true; // [s];
              if (isNotDlt && parseInt(d.dlt_api_type) === 0) return true;
            }
            return false;
          }
        }

        return false;
      });

      var objComs = filterKeys.map((k) => {
        return state[k];
      });

      // sort them
      if (isSort) {
        objComs.sort((a, b) => {
          return ((b['times'] / 1000) - (a['times'] / 1000));
        });
      }

      // my device
      if (isShowSelects) {
        objComs = objComs.filter((s) => {
          if (mySelects.includes(s.serial)) {
            return true;
          }
          return false;
        });
      }

      var dvComs = objComs.map((o, idx) => {
        try {
          if (o) {
            // o.idx = idx;
            // TODO: how to useCallback
            return (<div className="device-each" key={o.serial}><DeviceComponent
              favorites={mySelects}
              onSelected={deviceComponentOnSelected}
              onUnSelected={deviceComponentOnUnSelected}
              key={o.serial}
              device={o} />
            </div>);
          }

          return null;
        } catch (err) {
          console.log(err);
          return null;
        }
      });
      // console.timeEnd('exectimes');// const  devicesTotal = dvComs.length;	
      // const  devicesWithIssues = thi s.calculateTotalDeviceWithIssues(dvComs);// thi .set State({ devicesTotal, devicesWithIssues: -1 });

      // if found only 1 device so add empty col
      if (dvComs.length === 1) {
        dvComs.push(<div key={-1}></div>);
      }

      if (dvComs.length === 0) {
        dvComs.push(<div key={-2} className="device-not-found" >NOT FOUND</div>);
      }

      return dvComs;
    } catch (error) {
      console.log(error);
    }
  };

  // const calculateTotalDeviceWithIssues = (dvComs) => {
  // 	try {
  // 		let issueCount = 0;
  // 		dvComs.map((d) => {
  // 			var etime = new Date(d.times);
  // 			var ctime = new Date();
  // 			var diffMs = ctime - etime;
  // 			diffMs = diffMinutes(ctime, etime);
  // 			if (diffMs > (60 * 4)) {
  // 				issueCount++;
  // 			}
  // 		});
  // 		return issueCount;
  // 	} catch (error) {
  // 		console.log(error);
  // 	}
  // 	return 0;
  // }

  const diffMinutes = (dt1, dt2) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  // if (!is_chrome) {
  // 	return (<div>Your browser is not supported. </div>);
  // }

  if (!loaded) {
    // console.log('hi.')
    return (<div>Loading ...</div>);
  }

  if (ws_connection === 'waiting') {
    if (is_timeout === true) {
      return (
        <div className="web-socet-closed">
          Web socket is closed, please refresh this page or try again later.
        </div>
      )
    }
    return (
      <div className="ws-waiting">
        Waiting ...
      </div>
    )
  }
  if (ws_connection === 'connecting') {
    return (
      <div className="ws-connecting">
        CONNECTING...
      </div>
    )
  }

  return (
    <div className="container-full" >

      {/* what is zoom style ? s tyle={{ zoom: zoom }} */}

      {/* PC header */}
      <div className="container-row">

        {/* TITLE */}
        <div className="top-left"  >

          <div className="title-dm" >

            {/* Device Monitor */}

            <span className="lead-letter" >D
              {/* <div className="a-box-1">
							</div>
							<div className="a-box-2">
							</div>
							<div className="a-box-3">
							</div> */}
            </span>
            <span>EVICE </span>
            <span>&#160;</span>
            <span className="lead-letter" >M
              {/* <div className="a-box-1">
							</div>
							<div className="a-box-2">
							</div>
							<div className="a-box-3">
							</div> */}
            </span>
            <span>ONITOR</span>

            {/* "transport close" */}
            {is_transport_closed === false
              ?
              <TimerCom />
              :
              <div className="web-socet-closed">
                Web socket is closed, please refresh this page or try again later.
              </div>
            }

          </div>

          {/* TODO: add notification when add, remove decice	 */}
          {/* <div style={{ paddingTop: '10px', display: 'none' }}>

						
						<a title="Device monitor" style={{ paddingLeft: '10px', }} href="http://monitor.fleetlocate.asia/" rel="noopener noreferrer">
							<FaCar size="1.5em" style={{ color: '#b2bec3', margin: '0 0.5rem 0 0', }} />
						</a>

						
						<a title="Server monitor" style={{ paddingLeft: '10px', }} href="http://monitor.fleetlocate.asia/#/servermonitor" rel="noopener noreferrer">
							<FaServer size="1.3em" style={{ color: '#b2bec3', margin: '0 0.5rem 0 0', }} />
						</a>

						
						<a title="QR code generator" style={{ paddingLeft: '10px', }} href="http://coupon.fleetlocate.asia/#/" rel="noopener noreferrer" target="_blank" >
							<FaQrcode size="1.5em" style={{ color: '#b2bec3', margin: '0 0.5rem 0 0', }} />
						</a>
						
						<a title="QR code admin" style={{ paddingLeft: '10px', }} href="http://coupon.fleetlocate.asia/#/admin" rel="noopener noreferrer" target="_blank" >
							<FaQrcode size="1.5em" style={{ color: '#c0392b', margin: '0 0.5rem 0 0', }} />
						</a>

						
						<a title="Notifications" href="void(0)" rel="noopener noreferrer">
							<FaBell size="1.8em" style={{ paddingLeft: '10px', color: '#b2bec3', margin: '0 0.5rem 0 0', }} />
							<span style={{
								backgroundColor: '#c0392b',
								color: 'white',
								borderRadius: '50px',
								padding: '5px 8px',
								position: 'relative',
								marginLeft: '-15px',
								marginTop: '-22px',
								top: '-8px',
							}}>
								15
							</span>
						</a>
					</div> */}

          {/* CHART & OPTION SELECT */}
          <div className="chart-options" >
            <div className="chart">
              {/* <PieCom data={pieData} nodata={nodata} /> */}
              {/* TODO: GOOGLE CHART */}

              {/* const [devicesTotal, setDevicesTotal] = useState(0);
              const [devicesWithIssues, setDevicesWithIssues] = useState(0); */}

              <GoogleChart issues={devicesWithIssues} total={devicesTotal} />

            </div>

            <div className="options" >

              <ul className="switch">
                <li >
                  <input type="checkbox" checked={isDlt} onChange={() => {
                    setIsDlt(!isDlt);
                  }} />
                  <span >DLT</span>
                  <input type="checkbox" checked={isNotDlt} onChange={() => {
                    try {
                      setIsNotDlt(!isNotDlt);
                    } catch (error) {
                      debugger;
                    }
                  }
                  } />
                  <span >NOT DLT</span>
                </li>
                <li >
                  <input type="checkbox" checked={isSort} onChange={() => {
                    setIsSort(!isSort);
                  }} />
                  <span >Sort by event date</span>
                </li>
                <li >

                  <Rating stop={1}
                    {...props}
                    onClick={(v) => {
                      if (isShowSelects === true) {
                        setIsShowSelects(false);
                      } else {
                        setIsShowSelects(true);
                      }
                    }}
                    initialRating={isShowSelects}
                    fullSymbol={<FaStar color={'#e67e22'} size="1rem" />}
                    emptySymbol={<FaRegStar size="1rem" />}
                  />
                  <span >Favourites ({mySelects.length})</span>

                </li>

              </ul>

              {isShowSelects === false && (
                <ul className="device-numbers">
                  <li >
                    <div className="all-device" >
                      {(devicesTotal).toLocaleString()}
                    </div>
                    Devices are reporting to the server
                  </li>

                  <li style={{ marginTop: '5px', }}>
                    <div className="good-device">
                      {(devicesTotal - devicesWithIssues).toLocaleString()}
                    </div>
                    Devices are active
                  </li>

                  <li style={{ marginTop: '5px', }}>

                    <div className="bad-device" >
                      {(devicesWithIssues).toLocaleString()}
                    </div>
                    Devices are inactive more than 4 hours

                  </li>
                </ul>
              )}

            </div>
          </div>

        </div>


        <div className="top-right"  >

          {isShowSelects === false && (
            <SearchBox onCompleted={(res) => {
              setSearch(res);
            }} />
          )}


          {/* <div className="log-style-container">
            <div className="log-style">

              <div className="log-header">
                <span >

                  <FaHistory color="silver" />
                </span>
                <span >
                  
                  Recently event update
                </span>
              </div>

              <ul className="log" style={{}}>
                <LogCom data={deviceLog} width="1200px" />
              </ul>

              <ul className="log" style={{}}>

                

              </ul>
            </div>
          </div> */}

          <LogCom data={deviceLog} />


        </div>

      </div>


      {/* Row - Pie chart and Log box 
			#########################################
			{search.length === 0 && (
			*/}

      <div className="container-device" >
        {/* Why difference  */}
        {RenderDevices()}
        {/* <RenderDevices /> */}
      </div>

    </div >
  );
}

export default AppMain;


const SearchBox = ({ onCompleted }) => {

  const searchRef = useRef(null);

  const [searchHistories, setSearchHistories] = useState();

  const [searchVal, setSearchVal] = useState("");

  const debounceValue = useDebounce(searchVal, 2000);

  useEffect(() => {
    try {
      const _shs = localStorage.getItem('shs')
      if (_shs) {
        setSearchHistories(JSON.parse(_shs))
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  // 864352047933

  useEffect(() => {
    try {
      if (searchVal.length > 10) {
        // remove if exist 
        let arr = searchHistories || [];
        arr = arr.filter((o) => {
          return o !== searchVal
        })
        // if len > 5 
        arr = arr.slice(0, 8)
        arr.splice(0, 0, searchVal)
        const json = JSON.stringify(arr)
        localStorage.setItem('shs', json)
        setSearchHistories(arr)
      }
    } catch (error) {
      console.log(error)
    }

    onCompleted(searchVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const deleteHistory = (h) => {
    try {
      const arr = searchHistories.filter((o, index) => {
        if (index > 8) {
          console.log('Index > 5, no need to return')
          return false;
        }
        return o !== h
      })

      // if arrary len > 10 so remove the last one			

      const json = JSON.stringify(arr)
      localStorage.setItem('shs', json)
      setSearchHistories(arr)

    } catch (error) {
      console.log(error)
    }
  }

  const doHistory = (s) => {
    try {
      searchRef.current.value = s;

      // searchRef.current.focus();

      setSearchVal(s);

    } catch (error) {
      console.log(error)
    }
  }

  return (

    <div className="mb-x" style={{ position: 'relative', }}>

      <input ref={searchRef} type="text" placeholder="Search by serial"
        onChange={(e) => setSearchVal(e.target.value)}
      />

      <div className="search-history">
        <div className="mb-x-icon" >
          {/* <FaSearch color="silver" fontSize={12} /> */}
          {/* <GiTruck color="white" fontSize={15} />  */}
          <HiOutlineAdjustmentsHorizontal color="white" fontSize={25} />
        </div>
        <ul>
          {searchHistories && searchHistories.map((hs, index) => {

            return (<li key={index.toString()}>
              <button className="history-item"
                onMouseDown={() => doHistory(hs)}
              >
                <FaHistory color="silver" fontSize={12} />
                <span>
                  {hs}
                </span>
              </button>


              <button className="delete-history"
                onMouseDown={() => {
                  deleteHistory(hs)
                }}
              >
                <FaCut fontSize={12} />
              </button>

            </li>
            )
          })}

        </ul>
      </div>



      {/* <div className="mb-x-icon" >
				<Fa Search color="si lver" />
			</div> */}


    </div>

  )
}