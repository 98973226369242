import React, { memo, useEffect, useRef, useState } from 'react';
import _ from "lodash"
import moment from 'moment';

const TimerCom = memo(() => {
    // console.log(`TimerCom.js - render`)
    const timerRef = useRef(null)
    const [timer, setTimer] = useState(' initialing ... ');
    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTimer(moment().format("HH:mm:ss"))
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [])

    return (
        <div className='timer' >
            {timer}
        </div>
    );
}, (prev, next) => {
    return _.isEqual(prev, next)
})

export default TimerCom;
